import { defineNuxtRouteMiddleware, navigateTo } from 'nuxt/app';
import { convertSearchParamsToString } from '../helpers/search.helper';
import { TABS } from '../interfaces/filters.interface';

// TODO - add Test Unit for each rewrite rule
const redirectSearch = defineNuxtRouteMiddleware(async (to) => {
  if (to.query.tab) {
    const searchParams = { ...to.query }
    delete searchParams.tab
    const search_tab = Object.values(TABS).includes(to.query.tab as TABS) ? to.query.tab : TABS.ACTIVITIES
    
    // convert search params query string
    let queryString = convertSearchParamsToString(searchParams)
    queryString = queryString ? `?${queryString}` : ''

    // `params.search_tab` is must important than `query.tab`
    if (to.params.search_tab) {
      return navigateTo(`${to.path}${queryString}`)
    }
    
    // set `query.tab` to `params.search_tab`
    else {
      return navigateTo(`${to.path}/${search_tab}${queryString}`)
    }
  }
})

export default redirectSearch